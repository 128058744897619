import React, { FC, ReactNode, useEffect, useState } from "react";
import classNames from "classnames";

import { Pattern, PatternProps, Section, SectionProps } from "components";

import styles from "./LayoutHeader.module.css";

export interface LayoutHeaderProps extends SectionProps {
  isFixed?: boolean;
  isHidden?: boolean;
  isMenuOpened?: boolean;
  nav?: PatternProps;
  navLeft?: ReactNode;
  navLeftProps?: PatternProps;
  navRight?: ReactNode;
  navRightProps?: PatternProps;
}

export const LayoutHeader: FC<LayoutHeaderProps> = ({
  as = "header",
  children,
  is = "header",
  isFixed = false,
  isHidden = false,
  isMenuOpened = false,
  nav,
  navLeft,
  navLeftProps,
  navRight,
  navRightProps,
  pattern = "navbar",
  position = "absolute",
  ...rest
}) => {
  const [headerFixed, setHeaderFixed] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  if (isHidden) return null;

  const navProps = {
    order: 0,
    ...nav,
  };

  useEffect(() => {
    const onScroll = () => {
      let currentPosition = window.pageYOffset; // or use document.documentElement.scrollTop;
      // scrolling down
      if (currentPosition > scrollTop) {
        // scrolled to bottom
        // if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        //   setHeaderFixed(false);
        // } else {
        //   setHeaderFixed(true);
        // }
        setHeaderFixed(true);
      } else {
        // scrolled to top
        if (window.scrollY === 0) {
          setHeaderFixed(false);
        } else {
          setHeaderFixed(true);
        }
      }

      setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <Section
      as={as}
      is={is}
      className={classNames(
        styles.header,
        isMenuOpened
          ? `fixed top-0 left-0 h-screen lg:${position} lg:top-auto lg:left-auto lg:w-auto lg:h-auto`
          : isFixed || headerFixed
          ? styles.affixed
          : position && styles.transparent
      )}
      container={{
        className: styles.headerContainer,
      }}
      pattern={pattern}
      {...rest}
    >
      {navLeft && (
        <Pattern as="nav" is="nav" {...navProps} {...navLeftProps}>
          {navLeft}
        </Pattern>
      )}
      {children}
      {navRight && (
        <Pattern
          as="nav"
          is="nav"
          {...navProps}
          {...navRightProps}
          className={classNames("justify-end", navRightProps?.className)}
        >
          {navRight}
        </Pattern>
      )}
    </Section>
  );
};
