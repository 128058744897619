import React, { FC } from "react";
import classNames from "classnames";

import {
  Icon,
  Link,
  List,
  Pattern,
  Section,
  SectionProps,
  Text,
} from "components";
import { renderLayoutFooterLink } from "layouts";
import { useSiteMetadataQuery } from "graphql";

import styles from "./FormLayoutFooter.module.css";

export interface FormLayoutFooterProps extends SectionProps {
  isHidden?: boolean;
  showAuthor?: boolean;
  showOrg?: boolean;
  showSocialMediaLinks?: boolean;
}

export const FormLayoutFooter: FC<FormLayoutFooterProps> = ({
  as = "footer",
  children,
  className,
  is = "footer",
  isHidden = false,
  pattern = "navbar",
  showAuthor = true,
  showOrg = true,
  showSocialMediaLinks = false,
  ...rest
}) => {
  const { author, copyright, organization, socialMedia, subscribeURL } =
    useSiteMetadataQuery();
  const currentYear = new Date().getFullYear();

  if (isHidden) return null;

  return (
    <Section
      as={as}
      is={is}
      pattern={pattern}
      {...(rest as SectionProps)}
      className={classNames(styles.footer, className)}
    >
      {copyright && (
        <Pattern
          of="col"
          mod="flex-full md:flex-1 justify-end order-1 md:order-none"
        >
          <Text as="p" pattern="caption">
            © {copyright.year ? copyright.year : currentYear},
            {copyright.message && ` ${copyright.message}`}
            {showOrg && <> {renderLayoutFooterLink(organization)}.</>}
            {copyright.authorMessage && ` ${copyright.authorMessage}`}
            {showAuthor && <> {renderLayoutFooterLink(author)}.</>}
          </Text>
        </Pattern>
      )}
      {children}
      <Pattern of="col" mod="flex-full md:flex-1 items-center md:items-end">
        {showSocialMediaLinks && (
          <List
            as="nav"
            of="row"
            mod="space-x-4 md:space-x-5 xl:md-space-x-6 mt-4"
          >
            <Link
              className={classNames(styles.link, styles.facebook)}
              to={`https://www.facebook.com/${socialMedia?.facebook}`}
              pattern="button"
              button="icon"
            >
              <Icon name="facebook" size="sm" />
            </Link>
            <Link
              className={classNames(styles.link, styles.instagram)}
              to={`https://www.instagram.com/${socialMedia?.facebook}`}
              pattern="button"
              button="icon"
            >
              <Icon name="instagram" size="sm" />
            </Link>
            <Link
              className={classNames(styles.link, styles.linkedin)}
              to={`https://www.linkedin.com/company/${socialMedia?.linkedin}`}
              pattern="button"
              button="icon"
            >
              <Icon name="linkedin" size="sm" />
            </Link>
            <Link
              className={classNames(styles.link, styles.yelp)}
              to={`https://www.yelp.com/biz/${socialMedia?.facebook}`}
              pattern="button"
              button="icon"
            >
              <Icon name="yelp" size="sm" />
            </Link>
          </List>
        )}
        <List
          as="nav"
          of="row"
          mod="space-x-4 md:space-x-5 xl:space-x-6 my-3 md:mt-4 md:mb-0"
        >
          <Link
            className={styles.link}
            to="/about"
            pattern="button"
            text="caption"
          >
            About
          </Link>
          <Link
            className={styles.link}
            to="/services"
            pattern="button"
            text="caption"
          >
            Services
          </Link>
          <Link
            className={styles.link}
            to="/news"
            pattern="button"
            text="caption"
          >
            News
          </Link>
          {subscribeURL && (
            <Link
              className={styles.link}
              to={subscribeURL}
              pattern="button"
              text="caption"
            >
              Subscribe
            </Link>
          )}
        </List>
      </Pattern>
    </Section>
  );
};
