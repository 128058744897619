import React, { FC } from "react";
import { animated, config, useSpring } from "react-spring";
import classNames from "classnames";

import { Pattern, PatternProps, Section, SectionProps } from "components";
import { useTheme } from "contexts";

import styles from "./FormLayout.module.css";
import {
  FormLayoutFooter,
  FormLayoutFooterProps,
  FormLayoutHeader,
  FormLayoutHeaderProps,
} from "./components";
import { LayoutLogoProps } from "../default";

export interface FormLayoutProps extends SectionProps {
  footer?: FormLayoutFooterProps;
  header?: FormLayoutHeaderProps;
  logo?: LayoutLogoProps;
  main?: PatternProps;
}

export const FormLayout: FC<FormLayoutProps> = ({
  children,
  className,
  container,
  footer,
  header,
  is = "wrapper",
  logo,
  main,
  pattern = "layout",
  ...rest
}) => {
  const { theme } = useTheme();

  const sectionProps: PatternProps = {
    as: animated.div,
    pattern: pattern,
    style: useSpring({
      from: { opacity: 0 },
      to: { opacity: 1 },
    }),
    ...rest,
  };

  const containerProps: PatternProps = {
    ...container,
    as: animated.div,
    style: useSpring({
      config: config.gentle,
      from: { opacity: 0, transform: "translate3d(0, 50%, 0)" },
      to: { opacity: 1, transform: "translate3d(0, 0, 0)" },
    }),
    className: classNames(styles.panel, container?.className),
  };

  return (
    <Section
      {...sectionProps}
      container={containerProps}
      className={classNames(styles.layout, theme, className)}
    >
      <FormLayoutHeader {...header} logo={logo} />
      <Pattern
        as="main"
        is="main"
        {...main}
        className={classNames(styles.main, main?.className)}
      >
        {children}
      </Pattern>
      <FormLayoutFooter {...footer} />
    </Section>
  );
};
