import React, { FC } from "react";
import classNames from "classnames";

import {
  Icon,
  Link,
  List,
  Pattern,
  Section,
  SectionProps,
  Text,
} from "components";
import { useSiteMetadataQuery } from "graphql";
import { SiteMetadataAuthor, SiteMetadataOrganization } from "types/graphql";

import styles from "./LayoutFooter.module.css";

export type LayoutFooterPattern = "over" | "under" | "sticky";

export interface LayoutFooterProps extends SectionProps {
  isHidden?: boolean;
  showAuthor?: boolean;
  showOrg?: boolean;
  showSocialMediaLinks?: boolean;
  variant?: LayoutFooterPattern;
}

export const renderLayoutFooterLink = (
  link?: SiteMetadataAuthor | SiteMetadataOrganization
) =>
  link ? (
    <Link size="inherit" to={link?.url}>
      {link?.name}
    </Link>
  ) : null;

export const LayoutFooter: FC<LayoutFooterProps> = ({
  as = "footer",
  bgColor = "paper",
  color = "paper",
  children,
  className,
  container,
  is = "footer",
  isHidden = false,
  pattern = "navbar",
  showAuthor = true,
  showOrg = true,
  showSocialMediaLinks = false,
  variant,
  ...rest
}) => {
  const {
    author,
    copyright,
    footnote,
    organization,
    socialMedia,
    subscribeURL,
  } = useSiteMetadataQuery();
  const currentYear = new Date().getFullYear();

  if (isHidden) return null;

  return (
    <Section
      as={as}
      bgColor={bgColor}
      color={color}
      is={is}
      pattern={pattern}
      {...(rest as SectionProps)}
      className={classNames(
        styles.footer,
        variant && styles[variant],
        className
      )}
    >
      {copyright && (
        <Pattern
          of="col"
          mod="flex-full md:flex-1 justify-end order-1 md:order-none"
        >
          <Text as="p" pattern="caption">
            © {copyright.year ? copyright.year : currentYear},
            {copyright.message && ` ${copyright.message}`}
            {showOrg && <> {renderLayoutFooterLink(organization)}.</>}
            {copyright.authorMessage && ` ${copyright.authorMessage}`}
            {showAuthor && <> {renderLayoutFooterLink(author)}.</>}
          </Text>
          <Text as="p" pattern="caption">
            Review our{" "}
            <Link size="inherit" to="/privacy">
              privacy policy
            </Link>{" "}
            or read our{" "}
            <Link size="inherit" to="/disclaimer">
              legal disclaimer
            </Link>{" "}
            for copyright details.
          </Text>
          {footnote && (
            <Text as="p" pattern="caption">
              {footnote}
            </Text>
          )}
        </Pattern>
      )}
      {children}
      <Pattern of="col" mod="flex-full md:flex-1 items-center md:items-end">
        {showSocialMediaLinks && (
          <List
            as="nav"
            of="row"
            mod="space-x-4 md:space-x-5 xl:md-space-x-6 mt-4"
          >
            <Link
              className={classNames(styles.link, styles.facebook)}
              to={`https://www.facebook.com/${socialMedia?.facebook}`}
              pattern="button"
              button="icon"
            >
              <Icon name="facebook" size="sm" />
            </Link>
            <Link
              className={classNames(styles.link, styles.instagram)}
              to={`https://www.instagram.com/${socialMedia?.instagram}`}
              pattern="button"
              button="icon"
            >
              <Icon name="instagram" size="sm" />
            </Link>
            <Link
              className={classNames(styles.link, styles.linkedin)}
              to={`https://www.linkedin.com/company/${socialMedia?.linkedin}`}
              pattern="button"
              button="icon"
            >
              <Icon name="linkedin" size="sm" />
            </Link>
            <Link
              className={classNames(styles.link, styles.yelp)}
              to={`https://www.yelp.com/biz/${socialMedia?.yelp}`}
              pattern="button"
              button="icon"
            >
              <Icon name="yelp" size="sm" />
            </Link>
          </List>
        )}
        <List
          as="nav"
          of="row"
          mod="space-x-4 md:space-x-5 xl:space-x-6 my-3 md:mt-4 md:mb-0"
        >
          {subscribeURL && (
            <Link
              className={styles.link}
              to={subscribeURL}
              pattern="button"
              text="caption"
            >
              Subscribe
            </Link>
          )}
          <Link
            className={styles.link}
            to="/links"
            pattern="button"
            text="caption"
          >
            Links
          </Link>
          <Link
            className={styles.link}
            to="/about#jobs"
            pattern="button"
            text="caption"
          >
            Work with us
          </Link>
          {/* <Link
            className={styles.link}
            to="/contact"
            pattern="button"
            text="caption"
          >
            Contact us
          </Link> */}
        </List>
      </Pattern>
    </Section>
  );
};
