import React, { FC, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "classnames";

import {
  Box,
  Button,
  ButtonProps,
  Icon,
  Link,
  Pattern,
  PatternProps,
} from "components";
import { Brand, LogoName } from "containers";
import { useTheme } from "contexts";
import { useSiteMetadataQuery } from "graphql";

import { GatsbyLocation } from "types/gatsby";

import {
  LayoutFooter,
  LayoutFooterProps,
  LayoutHeader,
  LayoutHeaderProps,
  LayoutMenu,
  LayoutMenuProps,
} from "./components";
import styles from "./DefaultLayout.module.css";

export type LayoutPattern = "default" | "form";

export interface LayoutLogoProps {
  logoName?: LogoName;
  logoDark?: string;
  logoLight?: string;
}

export interface DefaultLayoutProps extends PatternProps {
  footer?: LayoutFooterProps;
  header?: LayoutHeaderProps;
  location?: GatsbyLocation;
  logo?: LayoutLogoProps;
  main?: PatternProps;
  menu?: LayoutMenuProps;
  pattern?: LayoutPattern;
  themeSwitch?: ButtonProps;
}

export const DefaultLayout: FC<DefaultLayoutProps> = ({
  children,
  className,
  footer,
  header,
  is = "wrapper",
  location,
  logo,
  main,
  menu,
  pattern = "default",
  themeSwitch,
  ...rest
}) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const { theme } = useTheme();
  const { title } = useSiteMetadataQuery();

  const toggleMenu = () => setMenuOpened(!menuOpened);

  // const themeSwitchProps: ButtonProps = {
  //   mod: "text-default hover:text-hover",
  //   ...themeSwitch,
  // };

  const menuProps: LayoutMenuProps = {
    mod: "lg:hidden",
    ...menu,
  };

  return (
    <Pattern
      is={is}
      {...(rest as PatternProps)}
      className={classNames(styles.layout, styles[pattern], theme, className)}
    >
      <LayoutHeader
        isMenuOpened={menuOpened}
        isFixed={header?.isFixed}
        navLeft={<Brand {...logo}>{title}</Brand>}
        navRight={
          <>
            <Link
              activeClassName="text-hover"
              className={styles.link}
              mod="hidden lg:inline-flex mr-6 xl:mr-8 leading-loose"
              pattern="button"
              text="caption"
              to="/about"
            >
              About
            </Link>
            <Box className="relative hidden lg:inline-flex">
              <Menu>
                <Box
                  as="span"
                  className="flex content-center items-center justify-between mr-6 xl:mr-8 leading-loose"
                  onMouseEnter={() => setDropdownOpened(true)}
                  onMouseLeave={() => setDropdownOpened(false)}
                >
                  <Link
                    activeClassName="text-hover"
                    className={styles.link}
                    pattern="button"
                    text="caption"
                    to="/services"
                  >
                    Services
                  </Link>
                </Box>
                <Transition
                  show={dropdownOpened}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  onMouseEnter={() => setDropdownOpened(true)}
                  onMouseLeave={() => setDropdownOpened(false)}
                >
                  <Menu.Items
                    className={classNames(styles.dropdown, "shadow")}
                    static
                  >
                    <Menu.Item>
                      <Link
                        activeClassName="text-hover"
                        mod="py-2 px-4"
                        pattern="button"
                        text="caption"
                        to="/services/residential"
                      >
                        Residential Services
                      </Link>
                    </Menu.Item>
                    <Menu.Item>
                      <Link
                        activeClassName="text-hover"
                        mod="py-2 px-4"
                        pattern="button"
                        text="caption"
                        to="/services/commercial"
                      >
                        Commercial Services
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </Box>
            <Link
              activeClassName="text-hover"
              className={styles.link}
              mod="hidden lg:inline-flex mr-6 xl:mr-8 leading-loose"
              pattern="button"
              text="caption"
              to="/news"
            >
              News
            </Link>
            {/* <Link
              className={styles.link}
              mod="hidden lg:inline-flex mr-6 xl:mr-8 leading-loose"
              pattern="button"
              text="caption"
              to="/contact"
            >
              Contact
            </Link> */}
            {/* <ThemeSwitch {...themeSwitchProps} /> */}
            <Button
              className={styles.link}
              color="transparent"
              mod="ml-6 lg:hidden leading-loose"
              pattern="icon"
              onClick={toggleMenu}
            >
              {menuOpened ? (
                <Icon name="x" size="lg" />
              ) : (
                <Icon name="menu" size="lg" />
              )}
            </Button>
          </>
        }
        {...header}
      >
        <LayoutMenu {...menuProps} isOpened={menuOpened}>
          <Link
            buttonProps={{ className: styles.button }}
            activeClassName="text-hover"
            className={styles.link}
            mod="p-2 sm:p-3 md:p-4"
            pattern="button"
            size="inherit"
            to="/about"
            onClick={toggleMenu}
          >
            About
            <Icon name="chevron-right" size="xl" />
          </Link>
          <Box className="relative">
            <Menu>
              {({ open }) => (
                <>
                  <Box
                    as="span"
                    className={classNames(
                      styles.button,
                      "flex content-center items-center justify-between p-2 sm:p-3 md:p-4"
                    )}
                  >
                    <Link
                      activeClassName="text-hover"
                      className={styles.link}
                      pattern="button"
                      size="inherit"
                      to="/services"
                    >
                      Services
                    </Link>
                    <Menu.Button className="focus:outline-none uppercase xl:-mt-1 ml-2 hover:text-hover">
                      {open ? (
                        <Icon name="chevron-down" size="xl" />
                      ) : (
                        <Icon name="chevron-right" size="xl" />
                      )}
                    </Menu.Button>
                  </Box>
                  <Transition
                    show={open}
                    enter="transition duration-100 ease-out"
                    enterFrom="transform opacity-0"
                    enterTo="transform opacity-100"
                    leave="transition duration-75 ease-out"
                    leaveFrom="transform opacity-100"
                    leaveTo="transform opacity-0"
                  >
                    <Menu.Items className={styles.accordion} static>
                      <Menu.Item>
                        <Link
                          activeClassName="text-hover"
                          className={styles.link}
                          mod="py-2 px-3 xs:px-4 sm:py-3 sm:px-5 md:px-6 text-gray-400"
                          pattern="button"
                          text="subtitle"
                          to="/services/residential"
                        >
                          Residential Services
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          activeClassName="text-hover"
                          className={styles.link}
                          mod="py-2 px-3 xs:px-4 sm:py-3 sm:px-5 md:px-6 text-gray-400"
                          pattern="button"
                          text="subtitle"
                          to="/services/commercial"
                        >
                          Commercial Services
                        </Link>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </Box>
          <Link
            buttonProps={{ className: styles.button }}
            activeClassName="text-hover"
            className={styles.link}
            mod="p-2 sm:p-3 md:p-4"
            pattern="button"
            size="inherit"
            to="/news"
            onClick={toggleMenu}
          >
            News
            <Icon name="chevron-right" size="xl" />
          </Link>
          <Link
            buttonProps={{ className: styles.button }}
            activeClassName="text-hover"
            className={styles.link}
            mod="p-2 sm:p-3 md:p-4"
            pattern="button"
            size="inherit"
            to="/contact"
            onClick={toggleMenu}
          >
            Contact
            <Icon name="chevron-right" size="xl" />
          </Link>
        </LayoutMenu>
      </LayoutHeader>
      <Pattern as="main" is="main" {...main}>
        {children}
      </Pattern>
      <LayoutFooter {...footer} />
    </Pattern>
  );
};
