import React, { FC } from "react";
import classNames from "classnames";

import { Section, SectionProps } from "components";
import { Brand } from "containers";
import { LayoutLogoProps } from "layouts";

import styles from "./FormLayoutHeader.module.css";

export interface FormLayoutHeaderProps extends SectionProps {
  isHidden?: boolean;
  logo?: LayoutLogoProps;
}

export const FormLayoutHeader: FC<FormLayoutHeaderProps> = ({
  as = "header",
  children,
  className,
  is = "header",
  isHidden,
  logo,
  pattern = "navbar",
  ...rest
}) => {
  if (isHidden) return null;

  return (
    <Section
      as={as}
      is={is}
      pattern={pattern}
      {...(rest as SectionProps)}
      className={classNames(styles.header, className)}
    >
      <Brand {...logo} />
    </Section>
  );
};
